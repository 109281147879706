import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchChildList = createAsyncThunk(
  "admin/child-list",
  async ({search ,filter ,limit ,offset}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.childListURL.replace("{search}", search).replace("{filter}", filter).replace("{limit}", limit).replace("{offset}", offset),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const childListSlice = createSlice({
  name: "child-list",
  initialState: {
    childList: [],
    childCount:null,
    fclFetching: false,
    fclSuccess: false,
    fclError: false,
    fclErrorMessage: "",
  },
  reducers: {
    fclClearState: (state) => {
      state.fclError = false;
      state.fclSuccess = false;
      state.fclFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchChildList.fulfilled]: (state, { payload }) => {
      state.childList = [];
      payload.data.results.forEach((item) => state.childList.push(item));
      state.childCount=payload.data.count;

      state.fclFetching = false;
      state.fclSuccess = true;
      return state;
    },
    [fetchChildList.rejected]: (state, action) => {
      state.fclFetching = false;
      state.fclError = true;
      state.fclErrorMessage = action?.payload;
    },
    [fetchChildList.pending]: (state) => {
      state.fclFetching = true;
    },
  },
});

export const { fclClearState } = childListSlice.actions;

export default childListSlice.reducer;
