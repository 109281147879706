import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const deleteAsset = createAsyncThunk(
  "admin/asset-delete",
  async ({ assetID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.assetDetailsURL.replace(
          "{id}",
          assetID
        ),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 204) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const assetDeleteSlice = createSlice({
  name: "asset-delete",
  initialState: {
    deleteAssetFetching: false,
    deleteAssetSuccess: false,
    deleteAssetError: false,
    deleteAssetErrorMessage: "",
  },
  reducers: {
    clearDeleteAssetState: (state) => {
      state.deleteAssetError = false;
      state.deleteAssetSuccess = false;
      state.deleteAssetFetching = false;

      return state;
    },
  },
  extraReducers: {
    [deleteAsset.fulfilled]: (state, { payload }) => {
      state.deleteAssetFetching = false;
      state.deleteAssetSuccess = true;
      return state;
    },
    [deleteAsset.rejected]: (state, action) => {
      state.deleteAssetFetching = false;
      state.deleteAssetError = true;
      state.deleteAssetErrorMessage = action?.payload;
    },
    [deleteAsset.pending]: (state) => {
      state.deleteAssetFetching = true;
    },
  },
});

export const { clearDeleteAssetState } =
  assetDeleteSlice.actions;

export default assetDeleteSlice.reducer;
