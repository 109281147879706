import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const updateLeaveStatus = createAsyncThunk(
  "admin/update-leave-status",
  async ({ payload, leaveID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.leaveStatusUpdateURL.replace("{id}", leaveID),
        method: "PATCH",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const updateLeaveStatusSlice = createSlice({
  name: "update-leave-status",
  initialState: {
    lsuFetching: false,
    lsuSuccess: false,
    lsuError: false,
    lsuErrorMessage: "",
  },
  reducers: {
    clearLsuState: (state) => {
      state.lsuError = false;
      state.lsuSuccess = false;
      state.lsuFetching = false;

      return state;
    },
  },
  extraReducers: {
    [updateLeaveStatus.fulfilled]: (state, { payload }) => {
      state.lsuFetching = false;
      state.lsuSuccess = true;
      return state;
    },
    [updateLeaveStatus.rejected]: (state, action) => {
      state.lsuFetching = false;
      state.lsuError = true;
      state.lsuErrorMessage = action?.payload;
    },
    [updateLeaveStatus.pending]: (state) => {
      state.lsuFetching = true;
    },
  },
});

export const { clearLsuState } = updateLeaveStatusSlice.actions;

export default updateLeaveStatusSlice.reducer;
