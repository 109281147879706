import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchLawyerList = createAsyncThunk(
  "admin/lawyer-list",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.lawyerListURL,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const lawyerListSlice = createSlice({
  name: "lawyer-list",
  initialState: {
    lawyerList: [],
    fllFetching: false,
    fllSuccess: false,
    fllError: false,
    fllErrorMessage: "",
  },
  reducers: {
    fllClearState: (state) => {
      state.fllError = false;
      state.fllSuccess = false;
      state.fllFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchLawyerList.fulfilled]: (state, { payload }) => {
      state.lawyerList = [];
      payload.data.results.forEach((item) => state.lawyerList.push(item));
      state.fllFetching = false;
      state.fllSuccess = true;
      return state;
    },
    [fetchLawyerList.rejected]: (state, action) => {
      state.fllFetching = false;
      state.fllError = true;
      state.fllErrorMessage = action?.payload;
    },
    [fetchLawyerList.pending]: (state) => {
      state.fllFetching = true;
    },
  },
});

export const { fllClearState } = lawyerListSlice.actions;

export default lawyerListSlice.reducer;
