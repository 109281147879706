import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const editCaseType = createAsyncThunk(
  "admin/legal-case-type-edit",
  async ({ payload, typeID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.legalCaseTypeEditURL.replace("{id}", typeID),
        method: "PATCH",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const legalCaseTypeEditSlice = createSlice({
  name: "legal-case-type-edit",
  initialState: {
    lcteFetching: false,
    lcteSuccess: false,
    lcteError: false,
    lcteErrorMessage: "",
  },
  reducers: {
    clearLcteState: (state) => {
      state.lcteError = false;
      state.lcteSuccess = false;
      state.lcteFetching = false;

      return state;
    },
  },
  extraReducers: {
    [editCaseType.fulfilled]: (state, { payload }) => {
      state.lcteFetching = false;
      state.lcteSuccess = true;
      return state;
    },
    [editCaseType.rejected]: (state, action) => {
      state.lcteFetching = false;
      state.lcteError = true;
      state.lcteErrorMessage = action?.payload;
    },
    [editCaseType.pending]: (state) => {
      state.lcteFetching = true;
    },
  },
});

export const { clearLcteState } = legalCaseTypeEditSlice.actions;

export default legalCaseTypeEditSlice.reducer;
