import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const deleteCaseType = createAsyncThunk(
  "admin/legal-case-type-delete",
  async ({ typeID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.legalCaseTypeEditURL.replace("{id}", typeID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 204) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const deleteCaseTypeSlice = createSlice({
  name: "legal-case-type-delete",
  initialState: {
    lctdFetching: false,
    lctdSuccess: false,
    lctdError: false,
    lctdErrorMessage: "",
  },
  reducers: {
    clearLctdState: (state) => {
      state.lctdError = false;
      state.lctdSuccess = false;
      state.lctdFetching = false;

      return state;
    },
  },
  extraReducers: {
    [deleteCaseType.fulfilled]: (state, { payload }) => {
      state.lctdFetching = false;
      state.lctdSuccess = true;
      return state;
    },
    [deleteCaseType.rejected]: (state, action) => {
      state.lctdFetching = false;
      state.lctdError = true;
      state.lctdErrorMessage = action?.payload;
    },
    [deleteCaseType.pending]: (state) => {
      state.lctdFetching = true;
    },
  },
});

export const { clearLctdState } = deleteCaseTypeSlice.actions;

export default deleteCaseTypeSlice.reducer;
