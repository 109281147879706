import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const addCheckbook = createAsyncThunk(
  "admin/checkbook-add",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.checkbookPostURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const checkbookCreateSlice = createSlice({
  name: "checkbook-add",
  initialState: {
    addChequeFetching: false,
    addChequeSuccess: false,
    addChequeError: false,
    addChequeErrorMessage: "",
  },
  reducers: {
    clearAddChequeState: (state) => {
      state.addChequeError = false;
      state.addChequeSuccess = false;
      state.addChequeFetching = false;

      return state;
    },
  },
  extraReducers: {
    [addCheckbook.fulfilled]: (state, { payload }) => {
      state.addChequeFetching = false;
      state.addChequeSuccess = true;
      return state;
    },
    [addCheckbook.rejected]: (state, action) => {
      state.addChequeFetching = false;
      state.addChequeError = true;
      state.addChequeErrorMessage = action?.payload;
    },
    [addCheckbook.pending]: (state) => {
      state.addChequeFetching = true;
    },
  },
});

export const { clearAddChequeState } =
  checkbookCreateSlice.actions;

export default checkbookCreateSlice.reducer;
