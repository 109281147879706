import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchUserAttendanceList = createAsyncThunk(
  "admin/child-attendance-lists",
  async ({childrenID}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.childAttendanceListsURL.replace("{id}",childrenID),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const attendanceListSlice = createSlice({
  name: "child-attendance-lists",
  initialState: {
    userAttendanceList: [],
    ualFetching: false,
    ualSuccess: false,
    ualError: false,
    ualErrorMessage: "",
  },
  reducers: {
    ualClearState: (state) => {
      state.ualError = false;
      state.ualSuccess = false;
      state.ualFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchUserAttendanceList.fulfilled]: (state, { payload }) => {
      state.userAttendanceList = [];
      payload.data.results.forEach((item) => state.userAttendanceList.push(item));

      state.ualFetching = false;
      state.ualSuccess = true;
      return state;
    },
    [fetchUserAttendanceList.rejected]: (state, action) => {
      state.ualFetching = false;
      state.ualError = true;
      state.ualErrorMessage = action?.payload;
    },
    [fetchUserAttendanceList.pending]: (state) => {
      state.ualFetching = true;
    },
  },
});

export const { ualClearState } = attendanceListSlice.actions;

export default attendanceListSlice.reducer;
