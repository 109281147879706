import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const editLegalCase = createAsyncThunk(
  "admin/legal-case-edit",
  async ({ payload, caseID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.legalCasesEditURL.replace("{id}", caseID),
        method: "PATCH",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const legalCaseEditSlice = createSlice({
  name: "legal-case-edit",
  initialState: {
    lceFetching: false,
    lceSuccess: false,
    lceError: false,
    lceErrorMessage: "",
  },
  reducers: {
    clearLceState: (state) => {
      state.lceError = false;
      state.lceSuccess = false;
      state.lceFetching = false;

      return state;
    },
  },
  extraReducers: {
    [editLegalCase.fulfilled]: (state, { payload }) => {
      state.lceFetching = false;
      state.lceSuccess = true;
      return state;
    },
    [editLegalCase.rejected]: (state, action) => {
      state.lceFetching = false;
      state.lceError = true;
      state.lceErrorMessage = action?.payload;
    },
    [editLegalCase.pending]: (state) => {
      state.lceFetching = true;
    },
  },
});

export const { clearLceState } = legalCaseEditSlice.actions;

export default legalCaseEditSlice.reducer;
