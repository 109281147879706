import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchLegalCasesList = createAsyncThunk(
  "admin/legal-cases-list",
  async ({search ,limit ,offset}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.legalCasesListURL.replace("{search}", search).replace("{limit}", limit).replace("{offset}", offset),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const legalCasesListSlice = createSlice({
  name: "legal-cases-list",
  initialState: {
    legalCasesList: [],
    lclFetching: false,
    lclSuccess: false,
    lclError: false,
    lclErrorMessage: "",
    legalCount: null,
  },
  reducers: {
    lclClearState: (state) => {
      state.lclError = false;
      state.lclSuccess = false;
      state.lclFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchLegalCasesList.fulfilled]: (state, { payload }) => {
      state.legalCasesList = [];
      payload.data.results.forEach((item) => state.legalCasesList.push(item));
      state.legalCount = payload.data.count;
      state.lclFetching = false;
      state.lclSuccess = true;
      return state;
    },
    [fetchLegalCasesList.rejected]: (state, action) => {
      state.lclFetching = false;
      state.lclError = true;
      state.lclErrorMessage = action?.payload;
    },
    [fetchLegalCasesList.pending]: (state) => {
      state.lclFetching = true;
    },
  },
});

export const { lclClearState } = legalCasesListSlice.actions;

export default legalCasesListSlice.reducer;
