import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchCheckbookDetails = createAsyncThunk(
  "admin/checkbook-details",
  async ({ checkID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.checkbookDetailsURL.replace("{id}", checkID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const checkbookDetailsSlice = createSlice({
  name: "checkbook-details",
  initialState: {
    checkbookDetails: {},
    etdFetching: false,
    etdSuccess: false,
    etdError: false,
    etdErrorMessage: "",
  },
  reducers: {
    clearEtdState: (state) => {
      state.etdError = false;
      state.etdSuccess = false;
      state.etdFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchCheckbookDetails.fulfilled]: (state, { payload }) => {
      state.checkbookDetails = payload.data;

      state.etdFetching = false;
      state.etdSuccess = true;
      return state;
    },
    [fetchCheckbookDetails.rejected]: (state, action) => {
      state.etdFetching = false;
      state.etdError = true;
      state.etdErrorMessage = action?.payload;
    },
    [fetchCheckbookDetails.pending]: (state) => {
      state.etdFetching = true;
    },
  },
});
export const { clearEtdState } = checkbookDetailsSlice.actions;

export default checkbookDetailsSlice.reducer;
