import axios from "axios";

const actionHandler = (payload) => {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Cache-Control"] = "no-cache";

  const token = localStorage.getItem("admin-token");
  if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }



  return new Promise((resolve, reject) => {
    //Production
    payload.baseURL = "https://sabari.enfono.com/prod/api_sabari/api/";

    axios(payload)
      .then((response) => {
        let resp = response.data;
        if (response.status >= 200 && response.status < 300) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};



axios.interceptors.response.use(undefined, function (err) {
  var statusCode = err.status;
  if (statusCode == undefined) {
    // Server needs to specify CORS headers in the response
    // Basically `ACCESS-CONTROL-ALLOW-ORIGIN: *`
    // Otherwise, these kinda issues happen

    var lineSplit = err.toString().split("\n")[0].split(" ");
    statusCode = lineSplit[lineSplit.length - 1];
  }
  return new Promise(() => {
    if (statusCode == 401 && err.config && !err.config.__isRetryRequest) {
      // Got an unauthorized, logout the staff
      localStorage.removeItem("admin-token");
      window.location.pathname = "/auth/sign-in";
    }
    throw err;
  });
});

export default {
  /* auth URLs */
  loginURL: "auth/login/", // [POST]
  logoutURL: "auth/logout/", // [POST]
  profileURL: "auth/profile/", // [GET, PUT]

  dashboardDataURL: "utilities/dashboard/",
  dashboardReportDetailsURL: "utilities/dashboard/financial_report/",

  //Child
  childListURL: "student/students/?search={search}&status={filter}&limit={limit}&offset={offset}", // [GET]
  onLeaveChildListURL:"student/students/?on_leave={onLeave}&limit={limit}&offset={offset}", // [GET]
  childPostURL: "student/students/",
  childDetailURL: "student/student/{id}/", // [GET, PUT, DELETE]
  childAdmissionDetailPostURL: "student/student/{id}/admission/", // [POST]
  childAttendanceListsURL: "student/student/{id}/attendance/",
  childAdmissionDetailURL: "student/student/{id}/admission_detailed/",
  markAttendanceURL: "student/student/{id}/mark_absent/",
  tempChildPostURL: "student/student/create_temp/",
  markAbsentURL: "student/student/{id}/mark_absent/",
  childrenDocumentListURL: "student/student/{id}/files/",
  childrenDocumentDetailURL: "student/file/{id}/",
  childAttendanceDeleteURL:"student/absent/{id}/",

  //Legal Case
  legalCasesListURL: "utilities/legal_cases/?search={search}&limit={limit}&offset={offset}",
  legalCasesAddURL: "utilities/legal_cases/",
  caseDetailsURL: "utilities/legal_case/{id}/",
  legalCasesEditURL: "utilities/legal_case/{id}/",
  caseCardDataURL: "utilities/case_card/",

  //Case Type
  legalCaseTypeAddURL: "utilities/case_types/",
  legalCaseTypeEditURL: "utilities/case_type/{id}/",
  caseTypeDetailsURL: "utilities/case_type/{id}/",
  legalCasesTypeListURL: "utilities/case_types/",

  //Lawyers
  lawyerListURL: "utilities/lawyers/",
  createLawyerURL: "utilities/lawyers/",
  lawyerDetailsURL: "utilities/lawyer/{id}/",

  //visitors Log
  visitorsLogListURL: "utilities/visitor_log/?search={search}&limit={limit}&offset={offset}",
  visitorsLogAddURL: "utilities/visitor_log/",
  visitorsLoDetailsURL: "utilities/visitor_log/{id}/",

  //Assets
  assetAddURL: "utilities/assets/",
  assetListURL: "utilities/assets/?search={search}&limit={limit}&offset={offset}",
  assetDetailsURL: "utilities/asset/{id}/",
  assetEditURL: "utilities/asset/{id}/",

  //Donators
  donatorsListURL: "utilities/donators/?search={search}&limit={limit}&offset={offset}",
  donatorPostURL: "utilities/donators/",
  donatorDetailsURL: "utilities/donator/{id}/",
  donationsListURL: "utilities/donator/{id}/donations/",

  //Dashboard
  dashboardHeaderData: "administration/landing/head_cards/",
  dashboardLeaveRequestListURL: "administration/landing/leave_requests/",
  dashboardCasesListURL: "administration/landing/cases_list/",
  dashboardAgreementListURL: "administration/landing/agreements/",

  //HR
  userListURL: "utilities/users/?search={search}&limit={limit}&offset={offset}",
  userPostURL: "utilities/users/",
  userDetailsURL: "utilities/user/{id}/",

  //Payroll
  salaryDetailsURL: "utilities/hr/salary/{id}/",
  salaryListURL: "utilities/hr/salaries/?month={month}&year={year}",
  salaryAddURL: "utilities/hr/salaries/",

  //Checkbook
  checkbookListURL: "utilities/cheque_details/?limit={limit}&offset={offset}",
  checkbookPostURL:"utilities/cheque_details/",
  checkbookDetailsURL: "utilities/check_detail/{id}/",

  //Transaction
  transactionListURL:"utilities/transactions/?type={type}&from_date={from_date}&to_date={to_date}&limit={limit}&offset={offset}&category={category}",
  transactionCreateURL: "utilities/transactions/",
  transactionDetailsURL: "utilities/transaction/{id}/",
  bankTransactionListURL:'utilities/bank_transactions/?type={type}&from_date={from_date}&to_date={to_date}&limit={limit}&offset={offset}&category={category}',

  //Transfers
  transferListURL: "utilities/transfer/?limit={limit}&offset={offset}",
  transferPostURL: "utilities/transfer/",
  transferDetailsURL: "utilities/transfer/{id}/",

  //Settings
  allSubCategoriesListURL: "administration/sub_categories/",
  adminUserDetailsURL: "administration/staff/{id}/",

  bankAccountListURL: "utilities/bank_accounts/",
  bankAccountDetailsURL: "utilities/bank_account/{id}/",

  businessDetailsListURL: "utilities/business/",
  businessDetailsPostURL: "settings/business_detail/create/",

  transactionCategoriesURL: "utilities/transaction_categories/?limit={limit}&offset={offset}&type={type}",
  transactionCategoriesPostURL: "utilities/transaction_categories/",
  transactionCategoryDetailsURL: "utilities/transaction_category/{id}/",

  transactionSubCategoriesURL:"utilities/transaction_category/{id}/sub_categories/?type={type}",
  transactionSubCategoriesPostURL:"utilities/transaction_category/{id}/sub_categories/",
  transactionSubCategoryDetailsURL: "utilities/transaction_sub_category/{id}/",

  //Dashboard
  attendanceMarkURL: "administration/attendance/",
  attendanceStatusURL: "administration/attendance/",
  userAttendanceListURL: "property/attendance_list/",

  //Documents
  documentListURL: "utilities/documents/",
  documentDetailsURL: "utilities/document/{id}/",

  //Attendance
  attendanceListURL: "utilities/hr/attendance/?year={year}&month={month}",

  leaveRequestListURL: "utilities/user/{id}/staff_attendance/",
  leaveDeleteURL: "utilities/staff_attendance/{id}/",

  //Reports
  reportsListURL: "utilities/financial_report/?from_date={from_date}&to_date={to_date}",

  actionHandler,
};
