import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchVisitorsLogDetails = createAsyncThunk(
  "admin/log-details",
  async ({ logID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.visitorsLoDetailsURL.replace("{id}", logID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const visitorsLogDetails = createSlice({
  name: "log-details",
  initialState: {
    logDetails: {},
    fldFetching: false,
    fldSuccess: false,
    fldError: false,
    fldErrorMessage: "",
  },
  reducers: {
    clearFldState: (state) => {
      state.fldError = false;
      state.fldSuccess = false;
      state.fldFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchVisitorsLogDetails.fulfilled]: (state, { payload }) => {
      state.logDetails = payload.data;

      state.fldFetching = false;
      state.fldSuccess = true;
      return state;
    },
    [fetchVisitorsLogDetails.rejected]: (state, action) => {
      state.fldFetching = false;
      state.fldError = true;
      state.fldErrorMessage = action?.payload;
    },
    [fetchVisitorsLogDetails.pending]: (state) => {
      state.fldFetching = true;
    },
  },
});
export const { clearFldState } = visitorsLogDetails.actions;

export default visitorsLogDetails.reducer;
