import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchBasicDetails = createAsyncThunk(
  "admin/basic-details",
  async ({ childrenID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.childDetailURL.replace("{id}", childrenID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const basicDetailsSlice = createSlice({
  name: "basic-details",
  initialState: {
    basicDetails: {},
    fbdFetching: false,
    fbdSuccess: false,
    fbdError: false,
    fbdErrorMessage: "",
  },
  reducers: {
    clearFbdState: (state) => {
      state.fbdError = false;
      state.fbdSuccess = false;
      state.fbdFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchBasicDetails.fulfilled]: (state, { payload }) => {
      state.basicDetails = payload.data;

      state.fbdFetching = false;
      state.fbdSuccess = true;
      return state;
    },
    [fetchBasicDetails.rejected]: (state, action) => {
      state.fbdFetching = false;
      state.fbdError = true;
      state.fbdErrorMessage = action?.payload;
    },
    [fetchBasicDetails.pending]: (state) => {
      state.fbdFetching = true;
    },
  },
});
export const { clearFbdState } = basicDetailsSlice.actions;

export default basicDetailsSlice.reducer;
