import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchStaffAttendanceList = createAsyncThunk(
  "admin/attendance-lists",
  async ({ year, month }, { rejectWithValue }) => {
    console.log('year', year , 'month', month);
    try {
      const response = await api.actionHandler({
        url:
           (year === null && month === null )
            ? api.attendanceListURL
                .replace("?year={year}", "")
                .replace("&month={month}", "")
            : api.attendanceListURL
                .replace("{year}", year)
                .replace("{month}", month),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const userAttendanceListSlice = createSlice({
  name: "attendance-lists",
  initialState: {
    userAttendanceList: [],
    salFetching: false,
    salSuccess: false,
    salError: false,
    salErrorMessage: "",
  },
  reducers: {
    salClearState: (state) => {
      state.salError = false;
      state.salSuccess = false;
      state.salFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchStaffAttendanceList.fulfilled]: (state, { payload }) => {
      state.userAttendanceList = [];
      payload.data.results.forEach((item) =>
        state.userAttendanceList.push(item)
      );

      state.salFetching = false;
      state.salSuccess = true;
      return state;
    },
    [fetchStaffAttendanceList.rejected]: (state, action) => {
      state.salFetching = false;
      state.salError = true;
      state.salErrorMessage = action?.payload;
    },
    [fetchStaffAttendanceList.pending]: (state) => {
      state.salFetching = true;
    },
  },
});

export const { salClearState } = userAttendanceListSlice.actions;

export default userAttendanceListSlice.reducer;
