import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchStudentDocumentsList = createAsyncThunk(
  "admin/student-documents-list",
  async ({childrenID}, rejectWithValue) => {
    try {
      const response = await api.actionHandler({
        url: api.childrenDocumentListURL.replace("{id}", childrenID),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const studentDocumentsListSlice = createSlice({
  name: "student-documents-list",
  initialState: {
    studentDocumentsList: [],
    sdlFetching: false,
    sdlSuccess: false,
    sdlError: false,
    sdlErrorMessage: "",
  },
  reducers: {
    sdlClearState: (state) => {
      state.sdlError = false;
      state.sdlSuccess = false;
      state.sdlFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchStudentDocumentsList.fulfilled]: (state, { payload }) => {
      state.studentDocumentsList = [];
      payload.data.results.forEach((item) => state.studentDocumentsList.push(item));
      state.sdlFetching = false;
      state.sdlSuccess = true;
      return state;
    },
    [fetchStudentDocumentsList.rejected]: (state, action) => {
      state.sdlFetching = false;
      state.sdlError = true;
      state.sdlErrorMessage = action?.payload;
    },
    [fetchStudentDocumentsList.pending]: (state) => {
      state.sdlFetching = true;
    },
  },
});

export const { sdlClearState } = studentDocumentsListSlice.actions;

export default studentDocumentsListSlice.reducer;
