import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const documentCreate = createAsyncThunk(
  "admin/document-create",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.documentListURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const documentsCreateSlice = createSlice({
  name: "document-create",
  initialState: {
    pdcFetching: false,
    pdcSuccess: false,
    pdcError: false,
    pdcErrorMessage: "",
  },
  reducers: {
    clearPdcState: (state) => {
      state.pdcError = false;
      state.pdcSuccess = false;
      state.pdcFetching = false;

      state.pdcErrorMessage = "";

      return state;
    },
  },
  extraReducers: {
    [documentCreate.fulfilled]: (state, { payload }) => {
      state.pdcFetching = false;
      state.pdcSuccess = true;
      return state;
    },
    [documentCreate.rejected]: (state, action) => {
      state.pdcFetching = false;
      state.pdcError = true;
      state.pdcErrorMessage = action?.payload;
    },
    [documentCreate.pending]: (state) => {
      state.pdcFetching = true;
    },
  },
});

export const { clearPdcState } = documentsCreateSlice.actions;

export default documentsCreateSlice.reducer;
