import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import settingReducer from "./setting/reducers";
import financialYearListSlice from "./misc/financialYearSlice";

//Login
import userSlice from "./login/loginSlice";
import userProfileSlice from "./profile/userProfileSlice";


import dashboardDataSlice from "./dashboard/dashboardDataSlice";
import dashboardReportSlice from "./dashboard/dashboardReportSlice";
import dashboardIncomeSlice from "./dashboard/dashboardIncomeSlice";

//Children
import childListSlice from "./childrens/childListSlice"; //Child List
import createBasicDetailsSlice from "./childrens/createBasicDetailsSlice";
import basicDetailsSlice from "./childrens/basicDetailsSlice";
import editBasicDetailsSlice from "./childrens/editBasicDetailsSlice";
import attendanceListSlice from "./childrens/attendanceListSlice";
import createAdmissionDetailsSlice from "./childrens/createAdmissionDetailsSlice";
import admissionDetailsSlice from "./childrens/admissionDetailsSlice";
import editAdmissionDetailsSlice from "./childrens/editAdmissionDetailsSlice";
import deleteChildrenSlice from "./childrens/deleteChildrenSlice";
import addAdmissionSlice from "./childrens/addAdmissionSlice";
import markAbsentSlice from "./childrens/markAbsentSlice";
import studentDocumentCreateSlice from "./childrens/studentDocumentCreateSlice";
import studentDocumentListSlice from "./childrens/studentDocumentListSlice";
import childDocumentDeleteSlice from "./childrens/childDocumentDeleteSlice";
import onLeaveChildListSlice from "./childrens/onLeaveChildListSlice";
import childLeaveDeleteSlice from "./childrens/childLeaveDeleteSlice";

//Visitors Log
import visitorsLogListSlice from "./visitorsLog/visitorsLogListSlice";
import createVisitorsLogSlice from "./visitorsLog/createVisitorsLogSlice";
import visitorsLogDeleteSlice from "./visitorsLog/visitorsLogDeleteSlice";
import visitorsLogDetailsSlice from "./visitorsLog/visitorsLogDetailsSlice";
import visitorsLodEditSlice from "./visitorsLog/visitorsLodEditSlice";

//Assets
import assetListSlice from "./assets/assetsListSlice";
import assetEditSlice from "./assets/assetEditSlice";
import assetAddSlice from "./assets/assetAddSlice";
import assetDetailsSlice from "./assets/assetDetails";
import assetDeleteSlice from "./assets/assetDeleteSlice";

//Legal
import legalCasesListSlice from "./legal/legalCasesListSlice";
import createLawyerSlice from "./legal/addAdvocateSlice";
import lawyerListSlice from "./legal/advocateListSlice";
import legalCaseTypeAddSlice from "./legal/caseTypeAddSlice";
import deleteCaseTypeSlice from "./legal/caseTypeDeleteSlice";
import caseTypeDetailsSlice from "./legal/caseTypeDetailsSlice";
import legalCaseTypeEditSlice from "./legal/caseTypeEditSlice";
import legalCasesTypeListSlice from "./legal/caseTypeListSlice";
import legalCaseAddSlice from "./legal/legalCaseAddSlice";
import caseDetailsSlice from "./legal/legalCaseDetailsSlice";
import legalCaseEditSlice from "./legal/legalCaseEditSlice";
import caseCardDataSlice from "./legal/legalCaseCardDataSlice";
import caseDeleteSlice from "./legal/caseDeleteSlice";
import deleteLawyerSlice from "./legal/deleteLawyerSlice";
import lawyerDetailsSlice from "./legal/lawyerDetailsSlice";
import editLawyerSlice from "./legal/editLawyerSlice";

//Donators
import donatorsListSlice from "./donators/donatorsListSlice";
import createDonatorsSlice from "./donators/createDonatorsSlice";
import donatorDetailsSlice from "./donators/donatorDetailsSlice";
import donatorEditSlice from "./donators/donatorsEditSlice";
import donatorDeleteSlice from "./donators/donatorDeleteSlice";
import donationsListSlice from "./donators/donationsListSlice";

//HR

import userLeaveListSlice from "./usermanagement/userLeaves/userLeaveListSlice";
import staffDeleteSlice from "./usermanagement/staff/staffDeleteSlice";
import staffListSlice from "./usermanagement/staff/staffListSlice";
import staffDetailsSlice from "./usermanagement/staff/staffDetailsSlice";
import staffEditSlice from "./usermanagement/staff/staffEditSlice";
import userAttendanceListSlice from "./usermanagement/attendance/userAttendanceListSlice";
import staffAddSlice from "./usermanagement/staff/staffAddSlice";

//Leave
import leaveRequestListSlice from "./usermanagement/leaves/leaveRequestListSlice";
import leaveRequestAddSlice from "./usermanagement/leaves/leaveRequestAddSlice";
import updateLeaveStatusSlice from "./usermanagement/leaves/leaveRequestUpdateSlice";
import leaveDeleteSlice from "./usermanagement/attendance/leaveDeleteSlice";

//Bank
import bankAccountListSlice from "./demoSettings/banks/bankAccountListSlice";
import addBankAccountSlice from "./demoSettings/banks/bankAccountAddSlice";
import bankAccountEditSlice from "./demoSettings/banks/bankAccountEditSlice";
import bankAccountDetailsSlice from "./demoSettings/banks/bankAccountDetailsSlice";
import deleteBankAccountSlice from "./demoSettings/banks/bankAccountDeleteSlice";

//Business Details
import businessDetailsSlice from "./demoSettings/business/businessDetailsListSlice";
import businessEditSlice from "./demoSettings/business/businessDetailsEditSlice";
import createBusinesDetailsSlice from "./demoSettings/business/createBusinesDetailsSlice";

//Payroll
import salaryListSlice from "./usermanagement/payroll/employeeSalary/salaryListSlice";
import salaryAddSlice from "./usermanagement/payroll/employeeSalary/salaryAddSlice";
import salaryDetailsSlice from "./usermanagement/payroll/employeeSalary/salaryDetailsSlice";
import salaryEditSlice from "./usermanagement/payroll/employeeSalary/salaryEditSlice";


//Categories & Sub Categories
import transCategoriesListSlice from "./demoSettings/transactionCategories/transCategoriesListSlice";
import transCategoriesAddSlice from "./demoSettings/transactionCategories/transCategoriesAddSlice";
import transCategoriesDetailsSlice from "./demoSettings/transactionCategories/transCategoriesDetailsSlice";
import transCategoriesEditSlice from "./demoSettings/transactionCategories/transCategoriesEditSlice";
import transCategoriesDeleteSlice from "./demoSettings/transactionCategories/transCategoriesDeleteSlice";

import transSubCategoriesListSlice from "./demoSettings/transactionSubCategories/transSubCategoriesListSlice";
import transSubCategoriesAddSlice from "./demoSettings/transactionSubCategories/transSubCategoriesAddSlice";
import transSubCategoriesDetailsSlice from "./demoSettings/transactionSubCategories/transSubCategoriesDetailsSlice";
import transSubCategoriesEditSlice from "./demoSettings/transactionSubCategories/transSubCategoriesEditSlice";
import transSubCategoriesDeleteSlice from "./demoSettings/transactionSubCategories/transSubCategoriesDeleteSlice";

//Accounts
//checkbook
import checkbookListSlice from "./accounting/checkbook/checkbookListSlice";
import checkbookCreateSlice from "./accounting/checkbook/checkbookCreateSlice";
import checkbookDetailsSlice from "./accounting/checkbook/checkbookDetailsSlice";
import checkbookEditSlice from "./accounting/checkbook/checkbookEditSlice";
import checkbookDeleteSlice from "./accounting/checkbook/checkbookDeleteSlice";

import transactionCreateSlice from "./accounting/transaction/transactionCreateSlice";
import transactionListSlice from "./accounting/transaction/transactionListSlice";
import transactionDetailsSlice from "./accounting/transaction/transactionDetailsSlice";
import transactionEditSlice from "./accounting/transaction/transactionEditSlice";
import transactionDeleteSlice from "./accounting/transaction/transactionDeleteSlice";


import transferCreateSlice from "./accounting/transfers/transferCreateSlice";
import transferListSlice from "./accounting/transfers/transferListSlice";
import transferDetailsSlice from "./accounting/transfers/transferDetailsSlice";
import transferEditSlice from "./accounting/transfers/transferEditSlice";
import transferDeleteSlice from "./accounting/transfers/transferDeleteSlice";

import bankTransactionsListSlice from "./accounting/bankTransactionsListSlice";
  
  
//Documents
import documentsListSlice from "./documents/documentsListSlice";
import documentsCreateSlice from "./documents/documentsCreateSlice";
import documentDeleteSlice from "./documents/documentDeleteSlice";

//Reports
import financeReportListSlice from "./reports/financeReportListSlice";


export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    setting: settingReducer,
    userSlice,
    staffListSlice,
    legalCasesListSlice,
    assetListSlice,
    financialYearListSlice,
    businessDetailsSlice,
    attendanceListSlice,
    leaveRequestListSlice,
    leaveRequestAddSlice,
    updateLeaveStatusSlice,
    businessEditSlice,
    bankAccountListSlice,
    addBankAccountSlice,
    bankAccountEditSlice,
    bankAccountDetailsSlice,
    createLawyerSlice,
    lawyerListSlice,
    legalCaseTypeAddSlice,
    deleteCaseTypeSlice,
    caseTypeDetailsSlice,
    legalCaseTypeEditSlice,
    legalCasesTypeListSlice,
    legalCaseAddSlice,
    caseDetailsSlice,
    legalCaseEditSlice,
    caseDeleteSlice,
    assetEditSlice,
    assetAddSlice,
    assetDetailsSlice,
    caseCardDataSlice,
    visitorsLogListSlice,
    createVisitorsLogSlice,
    visitorsLogDeleteSlice,
    visitorsLogDetailsSlice,
    visitorsLodEditSlice,
    staffDetailsSlice,
    staffDeleteSlice,
    salaryListSlice,
    salaryAddSlice,
    salaryDetailsSlice,
    salaryEditSlice,
    userLeaveListSlice,
    staffEditSlice,
    createBusinesDetailsSlice,

    deleteBankAccountSlice,
    deleteLawyerSlice,
    childLeaveDeleteSlice,

    //new
    childListSlice,
    onLeaveChildListSlice,
    createBasicDetailsSlice,
    basicDetailsSlice,
    editBasicDetailsSlice,
    donatorsListSlice,
    createDonatorsSlice,
    donatorDetailsSlice,
    donatorEditSlice,
    donatorDeleteSlice,
    donationsListSlice,
    attendanceListSlice,
    checkbookListSlice,
    checkbookCreateSlice,
    checkbookDetailsSlice,
    checkbookEditSlice,
    checkbookDeleteSlice,
    createAdmissionDetailsSlice,
    admissionDetailsSlice,
    editAdmissionDetailsSlice,
    deleteChildrenSlice,
    dashboardDataSlice,
    transCategoriesListSlice,
    transCategoriesAddSlice,
    transCategoriesDetailsSlice,
    transCategoriesEditSlice,
    transCategoriesDeleteSlice,
    transSubCategoriesListSlice,
    transSubCategoriesAddSlice,
    transSubCategoriesDetailsSlice,
    transSubCategoriesEditSlice,
    transSubCategoriesDeleteSlice,
    transactionCreateSlice,
    transactionListSlice,
    transactionDetailsSlice,
    transactionEditSlice,
    transactionDeleteSlice,
    documentsListSlice,
    documentsCreateSlice,
    documentDeleteSlice,
    transferCreateSlice,
    transferListSlice,
    transferDetailsSlice,
    transferEditSlice,
    transferDeleteSlice,
    dashboardReportSlice,
    addAdmissionSlice,
    markAbsentSlice,
    financeReportListSlice,
    dashboardIncomeSlice,
    studentDocumentCreateSlice,
    studentDocumentListSlice,
    userAttendanceListSlice,
    staffAddSlice,
    childDocumentDeleteSlice,
    leaveDeleteSlice,
    assetDeleteSlice,
    lawyerDetailsSlice,
    editLawyerSlice,
    userProfileSlice,
    bankTransactionsListSlice
  },
});
