import { useEffect ,useState } from "react";
//router
import IndexRouters from "./router/index";
import Loading1 from "./components/custom/loading1";
import { WhatsAppWidget } from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";


//scss
import "shepherd.js/dist/css/shepherd.css";
import "flatpickr/dist/flatpickr.css";
import "choices.js/public/assets/styles/choices.min.css";
import "./assets/scss/hope-ui.scss";
import "./assets/scss/pro.scss";
import "./assets/scss/custom.scss";
import "./assets/scss/dark.scss";
import "./assets/scss/rtl.scss";
import "./assets/scss/customizer.scss";
import "./assets/custom/scss/custom.scss";
import "@fullcalendar/common/main.css"; // @fullcalendar/react imports @fullcalendar/common
import "@fullcalendar/daygrid/main.css"; // @fullcalendar/timegrid imports @fullcalendar/daygrid

import "./App.css";

// Redux Selector / Action
import { useDispatch } from "react-redux";

// import state selectors
import { setSetting } from "./store/setting/actions";

function App() {
  const dispatch = useDispatch();
  dispatch(setSetting());
  // Loading state 
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
  
    // Wait for 3 seconds
    setTimeout(() => {
      setIsLoading(false);
    }, 600);
  }, []);

  useEffect(() => {}, []);
  return (
    <div className="App">
      {isLoading ? <Loading1 /> : <IndexRouters />}
      <WhatsAppWidget phoneNumber="+919544589040" />
    </div>
  );
}

export default App;
