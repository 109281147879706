import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchCaseTypeList = createAsyncThunk(
  "admin/legal-cases-type-list",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.legalCasesTypeListURL,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const legalCasesTypeListSlice = createSlice({
  name: "legal-cases-type-list",
  initialState: {
    legalCasesTypeList: [],
    lctlFetching: false,
    lctlSuccess: false,
    lctlError: false,
    lctlErrorMessage: "",
  },
  reducers: {
    lctlClearState: (state) => {
      state.lctlError = false;
      state.lctlSuccess = false;
      state.lctlFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchCaseTypeList.fulfilled]: (state, { payload }) => {
      state.legalCasesTypeList = [];
      payload.data.results.forEach((item) =>
        state.legalCasesTypeList.push(item)
      );

      state.lctlFetching = false;
      state.lctlSuccess = true;
      return state;
    },
    [fetchCaseTypeList.rejected]: (state, action) => {
      state.lctlFetching = false;
      state.lctlError = true;
      state.lctlErrorMessage = action?.payload;
    },
    [fetchCaseTypeList.pending]: (state) => {
      state.lctlFetching = true;
    },
  },
});

export const { lctlClearState } = legalCasesTypeListSlice.actions;

export default legalCasesTypeListSlice.reducer;
