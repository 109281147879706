import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchAssetList = createAsyncThunk(
  "admin/asset-list",
  async ({search ,limit ,offset}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.assetListURL.replace("{search}", search).replace("{limit}", limit).replace("{offset}", offset),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const assetListSlice = createSlice({
  name: "asset-list",
  initialState: {
    assetList: [],
    aslFetching: false,
    aslSuccess: false,
    aslError: false,
    aslErrorMessage: "",
    assetCount: null,
  },
  reducers: {
    aslClearState: (state) => {
      state.aslError = false;
      state.aslSuccess = false;
      state.aslFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchAssetList.fulfilled]: (state, { payload }) => {
      state.assetList = [];
      payload.data.results.forEach((item) => state.assetList.push(item));
      state.assetCount = payload.data.count;
      state.aslFetching = false;
      state.aslSuccess = true;
      return state;
    },
    [fetchAssetList.rejected]: (state, action) => {
      state.aslFetching = false;
      state.aslError = true;
      state.aslErrorMessage = action?.payload;
    },
    [fetchAssetList.pending]: (state) => {
      state.aslFetching = true;
    },
  },
});

export const { aslClearState } = assetListSlice.actions;

export default assetListSlice.reducer;
