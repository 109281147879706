import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const addLegalCase = createAsyncThunk(
  "admin/legal-case-add",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.legalCasesAddURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const legalCaseAddSlice = createSlice({
  name: "legal-case-add",
  initialState: {
    lcaFetching: false,
    lcaSuccess: false,
    lcaError: false,
    lcaErrorMessage: "",
  },
  reducers: {
    clearLcaState: (state) => {
      state.lcaError = false;
      state.lcaSuccess = false;
      state.lcaFetching = false;

      return state;
    },
  },
  extraReducers: {
    [addLegalCase.fulfilled]: (state, { payload }) => {
      state.lcaFetching = false;
      state.lcaSuccess = true;
      return state;
    },
    [addLegalCase.rejected]: (state, action) => {
      state.lcaFetching = false;
      state.lcaError = true;
      state.lcaErrorMessage = action?.payload;
    },
    [addLegalCase.pending]: (state) => {
      state.lcaFetching = true;
    },
  },
});

export const { clearLcaState } = legalCaseAddSlice.actions;

export default legalCaseAddSlice.reducer;
