import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const deleteChildren = createAsyncThunk(
  "admin/delete-children",
  async ({ childrenID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.childDetailURL.replace("{id}", childrenID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 204) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const deleteChildrenSLice = createSlice({
  name: "delete-children",
  initialState: {
    dcdFetching: false,
    dcdSuccess: false,
    dcdError: false,
    dcdErrorMessage: "",
  },
  reducers: {
    dcdClearState: (state) => {
      state.dcdError = false;
      state.dcdSuccess = false;
      state.dcdFetching = false;

      return state;
    },
  },
  extraReducers: {
    [deleteChildren.fulfilled]: (state, { payload }) => {
      state.dcdFetching = false;
      state.dcdSuccess = true;
      return state;
    },
    [deleteChildren.rejected]: (state, action) => {
      state.dcdFetching = false;
      state.dcdError = true;
      state.dcdErrorMessage = action?.payload;
    },
    [deleteChildren.pending]: (state) => {
      state.dcdFetching = true;
    },
  },
});

export const { dcdClearState } = deleteChildrenSLice.actions;

export default deleteChildrenSLice.reducer;
