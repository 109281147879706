import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const createDonators = createAsyncThunk(
  "admin/create-donators",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.donatorPostURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const createDonatorsSlice = createSlice({
  name: "create-donators",
  initialState: {
    cdlFetching: false,
    cdlSuccess: false,
    cdlError: false,
    cdlErrorMessage: "",
  },
  reducers: {
    clearCdlState: (state) => {
      state.cdlError = false;
      state.cdlSuccess = false;
      state.cdlFetching = false;

      return state;
    },
  },
  extraReducers: {
    [createDonators.fulfilled]: (state, { payload }) => {
      state.cdlFetching = false;
      state.cdlSuccess = true;
      return state;
    },
    [createDonators.rejected]: (state, action) => {
      state.cdlFetching = false;
      state.cdlError = true;
      state.cdlErrorMessage = action?.payload;
    },
    [createDonators.pending]: (state) => {
      state.cdlFetching = true;
    },
  },
});

export const { clearCdlState } = createDonatorsSlice.actions;

export default createDonatorsSlice.reducer;
