import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchBankTransactionList = createAsyncThunk(
  "admin/cashbook/bank-Transaction-list",
  async (
    { type, fromDate, toDate, limit, offset, category },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.actionHandler({
        url:
          fromDate === "" || toDate === ""
            ? api.bankTransactionListURL
                .replace("{type}", type)
                .replace("&from_date={from_date}&", "")
                .replace("to_date={to_date}", "")
                .replace("{limit}", limit)
                .replace("{offset}", offset)
                .replace("{category}", category)
            : api.bankTransactionListURL
                .replace("{type}", type)
                .replace("{from_date}", fromDate)
                .replace("{to_date}", toDate)
                .replace("{limit}", limit)
                .replace("{offset}", offset)
                .replace("{category}", category),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      console.log("catched error: ", e);
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const bankTransactionsListSlice = createSlice({
  name: "bank-Transaction-list",
  initialState: {
    bankTransactionList: [],
    bankTransactionFetching: false,
    bankTransactionSuccess: false,
    bankTransactionError: false,
    bankTransactionErrorMessage: "",
    bankTransactionCount: null,
  },
  reducers: {
    bankTransactionClearState: (state) => {
      state.bankTransactionError = false;
      state.bankTransactionSuccess = false;
      state.bankTransactionFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchBankTransactionList.fulfilled]: (state, { payload }) => {
      state.bankTransactionList = [];
      payload.data.results.forEach((item) => state.bankTransactionList.push(item));
      state.bankTransactionCount = payload.data.count;
      state.bankTransactionFetching = false;
      state.bankTransactionSuccess = true;
      return state;
    },
    [fetchBankTransactionList.rejected]: (state, action) => {
      state.bankTransactionFetching = false;
      state.bankTransactionError = true;
      state.bankTransactionErrorMessage = action?.payload;
    },
    [fetchBankTransactionList.pending]: (state) => {
      state.bankTransactionFetching = true;
    },
  },
});

export const { bankTransactionClearState } = bankTransactionsListSlice.actions;

export default bankTransactionsListSlice.reducer;
