import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchVisitorsLog = createAsyncThunk(
  "admin/visitors-log-list",
  async ({limit ,offset ,search}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.visitorsLogListURL.replace("{limit}", limit).replace("{offset}", offset).replace("{search}",search) ,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const visitorsLogListSlice = createSlice({
  name: "visitors-log-list",
  initialState: {
    visitorsLogList: [],
    vllFetching: false,
    vllSuccess: false,
    vllError: false,
    vllErrorMessage: "",
    visitorsCount:null,
  },
  reducers: {
    vllClearState: (state) => {
      state.vllError = false;
      state.vllSuccess = false;
      state.vllFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchVisitorsLog.fulfilled]: (state, { payload }) => {
      state.visitorsLogList = [];
      payload.data.results.forEach((item) => state.visitorsLogList.push(item));
      state.visitorsCount = payload.data.count;
      state.vllFetching = false;
      state.vllSuccess = true;
      return state;
    },
    [fetchVisitorsLog.rejected]: (state, action) => {
      state.vllFetching = false;
      state.vllError = true;
      state.vllErrorMessage = action?.payload;
    },
    [fetchVisitorsLog.pending]: (state) => {
      state.vllFetching = true;
    },
  },
});

export const { vllClearState } = visitorsLogListSlice.actions;

export default visitorsLogListSlice.reducer;
