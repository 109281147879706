import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchUserLeaveList = createAsyncThunk(
  "admin/staff-leave-list",
  async ({userID}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.userLeaveListURL.replace("{id}",userID),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const userLeavesListSlice = createSlice({
  name: "staff-leave-list",
  initialState: {
    userLeaveList: [],
    ullFetching: false,
    ullSuccess: false,
    ullError: false,
    ullErrorMessage: "",
  },
  reducers: {
    ullClearState: (state) => {
      state.ullError = false;
      state.ullSuccess = false;
      state.ullFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchUserLeaveList.fulfilled]: (state, { payload }) => {
      state.userLeaveList = [];
      payload.data.results.forEach((item) => state.userLeaveList.push(item));

      state.ullFetching = false;
      state.ullSuccess = true;
      return state;
    },
    [fetchUserLeaveList.rejected]: (state, action) => {
      state.ullFetching = false;
      state.ullError = true;
      state.ullErrorMessage = action?.payload;
    },
    [fetchUserLeaveList.pending]: (state) => {
      state.ullFetching = true;
    },
  },
});

export const { ullClearState } = userLeavesListSlice.actions;

export default userLeavesListSlice.reducer;
