import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchCaseCardData = createAsyncThunk(
  "admin/case-card-data",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.caseCardDataURL,
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const caseCardDataSlice = createSlice({
  name: "case-card-data",
  initialState: {
    caseCardData: {},
    ccdFetching: false,
    ccdSuccess: false,
    ccdError: false,
    ccdErrorMessage: "",
  },
  reducers: {
    clearCcdState: (state) => {
      state.ccdError = false;
      state.ccdSuccess = false;
      state.ccdFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchCaseCardData.fulfilled]: (state, { payload }) => {
      state.caseCardData = payload.data;

      state.ccdFetching = false;
      state.ccdSuccess = true;
      return state;
    },
    [fetchCaseCardData.rejected]: (state, action) => {
      state.ccdFetching = false;
      state.ccdError = true;
      state.ccdErrorMessage = action?.payload;
    },
    [fetchCaseCardData.pending]: (state) => {
      state.ccdFetching = true;
    },
  },
});
export const { clearCcdState } = caseCardDataSlice.actions;

export default caseCardDataSlice.reducer;
