import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const createAdmissionDetails = createAsyncThunk(
  "admin/child-admission-add",
  async ({ payload  }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.tempChildPostURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const createAdmissionDetailsSlice = createSlice({
  name: "child-admission-add",
  initialState: {
    cadFetching: false,
    cadSuccess: false,
    cadError: false,
    cadErrorMessage: "",
  },
  reducers: {
    clearCadState: (state) => {
      state.cadError = false;
      state.cadSuccess = false;
      state.cadFetching = false;

      return state;
    },
  },
  extraReducers: {
    [createAdmissionDetails.fulfilled]: (state, { payload }) => {
      console.log(payload)
      state.cadFetching = false;
      state.cadSuccess = true;
      return state;
    },
    [createAdmissionDetails.rejected]: (state, action) => {
      state.cadFetching = false;
      state.cadError = true;
      state.cadErrorMessage = action?.payload;
    },
    [createAdmissionDetails.pending]: (state) => {
      state.cadFetching = true;
    },
  },
});

export const { clearCadState } = createAdmissionDetailsSlice.actions;

export default createAdmissionDetailsSlice.reducer;
