import { memo, lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";

//TransitionGroup
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Loading1 from "../components/custom/loading1";

const Index = lazy(() => import("../views/dashboard/index"));

//Children's
const ChildrenDashboard = lazy(() =>
  import("../views/Childresns/ChildrenDashboard")
);
const AddChildren = lazy(() => import("../views/Childresns/AddChildren"));
const ChildrenProfileDashboard = lazy(() =>
  import("../views/Childresns/Dashboard/index")
);

//Donations
const Donations = lazy(() => import("../views/Donations/Donations"));
const DonationDashboard = lazy(() =>
  import("../views/Donations/Dashboard/Dashboard")
);

//Asset Management
const Assets = lazy(() => import("../views/Assets/index"));

//HR
const HR = lazy(() => import("../views/HR/index"));
// const UserDashboard = lazy(() => import("../views/HR/UserDashboard"));

//Cashbook
const ExpenseTransaction = lazy(() =>import("../views/cashbook/expense/index"));
const IncomeTransaction = lazy(() => import("../views/cashbook/income/index"));
const Checkbook = lazy(() => import("../views/cashbook/checkbook/Checkbook"));
const Transfer = lazy(() => import("../views/cashbook/transfers/Transfers"));
const BankTransactions = lazy(() =>import('../views/cashbook/BankTransactions'));

//Visitor Log
const VisitorsLog = lazy(() => import("../views/VisitorsLog/Index"));

//Documents
const Documents = lazy(() => import("../views//Documents/Documents"));


//Legal Cases
const LegalCases = lazy(() => import("../views/legalcases/index"));
const CaseDetails = lazy(() => import("../views/legalcases/caseDetails"));

//Reports
const Reports =lazy(()=>import('../views/reports/IncomevsExpense'))

//Settings
const Settings = lazy(() => import("../views/settings/index"));

//Profile
const UserProfile = lazy(() => import("../views/Profile/ProfileDashboard"));

const DefaultRouter = memo(() => {
  return (
    <TransitionGroup>
      <CSSTransition classNames="fadein" timeout={300}>
        <Suspense fallback={<Loading1 />}>
          <Switch>
            <Route path="/dashboard" exact component={Index} />
            <Route path="/general-documents" exact component={Documents} />
            <Route
              exact
              path="/children/:active_tab?"
              component={ChildrenDashboard}
            />
            <Route
              path="/children/children-list/add-children"
              component={AddChildren}
            />
            <Route
              path="/children/children-list/:childrenID/:active_tab?"
              component={ChildrenProfileDashboard}
            />

            <Route path="/donators" exact component={Donations} />
            <Route
              path="/donators/:donatorID/:active_tab?"
              component={DonationDashboard}
            />

            <Route exact path="/assets" component={Assets} />

            <Route exact path="/legal-cases" component={LegalCases} />
            <Route
              path="/legal-cases/:caseID/case-details"
              component={CaseDetails}
            />

            <Route exact path="/hr-management/:active_tab?" component={HR} />

            <Route path="/settings/:active_tab" component={Settings} />

            {/*Cashbook */}
            <Route path="/cashbook/expenses" component={ExpenseTransaction} />
            <Route path="/cashbook/income" component={IncomeTransaction} />
            <Route path="/cashbook/chequebook" component={Checkbook} />
            <Route path="/cashbook/transfers" component={Transfer} />
            <Route path="/cashbook/bank-transactions" component={BankTransactions} />


            <Route path="/visitors-log" component={VisitorsLog} />
            <Route path="/report/finance" component={Reports} />

            <Route path="/staff-profile" component={UserProfile} />
          </Switch>
        </Suspense>
      </CSSTransition>
    </TransitionGroup>
  );
});

DefaultRouter.displayName = "DefaultRouter";
export default DefaultRouter;
