import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const markAbsent = createAsyncThunk(
  "admin/absent-add",
  async ({childrenID, payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.markAbsentURL.replace("{id}",childrenID),
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const markAbsentSlice = createSlice({
  name: "absent-add",
  initialState: {
    camFetching: false,
    camSuccess: false,
    camError: false,
    camErrorMessage: "",
    basicID: null,
    status:""
  },
  reducers: {
    clearCamState: (state) => {
      state.camError = false;
      state.camSuccess = false;
      state.camFetching = false;
      

      return state;
    },
  },
  extraReducers: {
    [markAbsent.fulfilled]: (state, { payload }) => {
      state.basicID = payload.data.id;
      state.camFetching = false;
      state.camSuccess = true;
      state.status = payload.status;
      return state;
    },
    [markAbsent.rejected]: (state, action) => {
      state.camFetching = false;
      state.camError = true;
      state.camErrorMessage = action?.payload;
    },
    [markAbsent.pending]: (state) => {
      state.camFetching = true;
      state.basicID = null;
    },
  },
});

export const { clearCamState } = markAbsentSlice.actions;

export default markAbsentSlice.reducer;
