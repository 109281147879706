import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const deleteCheckbook = createAsyncThunk(
  "admin/checkbook-delete",
  async ({ checkID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.checkbookDetailsURL.replace("{id}", checkID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const checkbookDeleteSlice = createSlice({
  name: "checkbook-delete",
  initialState: {
    deleteCheckbookFetching: false,
    deleteCheckbookSuccess: false,
    deleteCheckbookError: false,
    deleteCheckbookErrorMessage: "",
  },
  reducers: {
    clearDeleteCheckbookState: (state) => {
      state.deleteCheckbookError = false;
      state.deleteCheckbookSuccess = false;
      state.deleteCheckbookFetching = false;

      return state;
    },
  },
  extraReducers: {
    [deleteCheckbook.fulfilled]: (state, { payload }) => {
      state.deleteCheckbookFetching = false;
      state.deleteCheckbookSuccess = true;
      return state;
    },
    [deleteCheckbook.rejected]: (state, action) => {
      state.deleteCheckbookFetching = false;
      state.deleteCheckbookError = true;
      state.deleteCheckbookErrorMessage = action?.payload;
    },
    [deleteCheckbook.pending]: (state) => {
      state.deleteCheckbookFetching = true;
    },
  },
});

export const { clearDeleteCheckbookState } =
  checkbookDeleteSlice.actions;

export default checkbookDeleteSlice.reducer;
