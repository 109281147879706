import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const donatorEdit = createAsyncThunk(
  "admin/donate-edit",
  async ({payload, donatorID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.donatorDetailsURL.replace("{id}", donatorID),
        method: "PATCH",
        data:payload
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const donatorEditSlice = createSlice({
  name: "donate-edit",
  initialState: {
    eddFetching: false,
    eddSuccess: false,
    eddError: false,
    eddErrorMessage: "",
  },
  reducers: {
    clearEddState: (state) => {
      state.eddError = false;
      state.eddSuccess = false;
      state.eddFetching = false;

      return state;
    },
  },
  extraReducers: {
    [donatorEdit.fulfilled]: (state, { payload }) => {
      state.eddFetching = false;
      state.eddSuccess = true;
      return state;
    },
    [donatorEdit.rejected]: (state, action) => {
      state.eddFetching = false;
      state.eddError = true;
      state.eddErrorMessage = action?.payload;
    },
    [donatorEdit.pending]: (state) => {
      state.eddFetching = true;
    },
  },
});
export const { clearEddState } = donatorEditSlice.actions;

export default donatorEditSlice.reducer;
