import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const deleteDonator = createAsyncThunk(
  "admin/delete-donator",
  async ({ donatorID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.donatorDetailsURL.replace("{id}", donatorID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const donatorDeleteSlice = createSlice({
  name: "delete-donator",
  initialState: {
    ddsFetching: false,
    ddsSuccess: false,
    ddsError: false,
    ddsErrorMessage: "",
  },
  reducers: {
    ddsClearState: (state) => {
      state.ddsError = false;
      state.ddsSuccess = false;
      state.ddsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [deleteDonator.fulfilled]: (state, { payload }) => {
      state.ddsFetching = false;
      state.ddsSuccess = true;
      return state;
    },
    [deleteDonator.rejected]: (state, action) => {
      state.ddsFetching = false;
      state.ddsError = true;
      state.ddsErrorMessage = action?.payload;
    },
    [deleteDonator.pending]: (state) => {
      state.ddsFetching = true;
    },
  },
});

export const { ddsClearState } = donatorDeleteSlice.actions;

export default donatorDeleteSlice.reducer;
