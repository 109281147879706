import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const createBasicDetails = createAsyncThunk(
  "admin/child-add",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.childPostURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const createBasicDetailsSlice = createSlice({
  name: "child-add",
  initialState: {
    ccbdFetching: false,
    ccbdSuccess: false,
    ccbdError: false,
    ccbdErrorMessage: "",
    basicID: null,
    status:""
  },
  reducers: {
    clearCcbdState: (state) => {
      state.ccbdError = false;
      state.ccbdSuccess = false;
      state.ccbdFetching = false;
      

      return state;
    },
  },
  extraReducers: {
    [createBasicDetails.fulfilled]: (state, { payload }) => {
      state.basicID = payload.data.id;
      state.ccbdFetching = false;
      state.ccbdSuccess = true;
      state.status = payload.status;
      return state;
    },
    [createBasicDetails.rejected]: (state, action) => {
      state.ccbdFetching = false;
      state.ccbdError = true;
      state.ccbdErrorMessage = action?.payload;
    },
    [createBasicDetails.pending]: (state) => {
      state.ccbdFetching = true;
      state.basicID = null;
    },
  },
});

export const { clearCcbdState } = createBasicDetailsSlice.actions;

export default createBasicDetailsSlice.reducer;
