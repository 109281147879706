import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const editLawyer = createAsyncThunk(
  "admin/lawyer-edit",
  async ({ payload, lawyerID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.lawyerDetailsURL.replace("{id}", lawyerID),
        method: "PATCH",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const editLawyerSlice = createSlice({
  name: "lawyer-edit",
  initialState: {
    ldeFetching: false,
    ldeSuccess: false,
    ldeError: false,
    ldeErrorMessage: "",
  },
  reducers: {
    clearLdeState: (state) => {
      state.ldeError = false;
      state.ldeSuccess = false;
      state.ldeFetching = false;

      return state;
    },
  },
  extraReducers: {
    [editLawyer.fulfilled]: (state, { payload }) => {
      state.ldeFetching = false;
      state.ldeSuccess = true;
      return state;
    },
    [editLawyer.rejected]: (state, action) => {
      state.ldeFetching = false;
      state.ldeError = true;
      state.ldeErrorMessage = action?.payload;
    },
    [editLawyer.pending]: (state) => {
      state.ldeFetching = true;
    },
  },
});

export const { clearLdeState } = editLawyerSlice.actions;

export default editLawyerSlice.reducer;
