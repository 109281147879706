import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const deleteLeave = createAsyncThunk(
  "admin/delete-leave",
  async ({ leaveID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.leaveDeleteURL.replace("{id}", leaveID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 204) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const leaveDeleteSlice = createSlice({
  name: "delete-leave",
  initialState: {
    leaveDeleteFetching: false,
    leaveDeleteSuccess: false,
    leaveDeleteError: false,
    leaveDeleteErrorMessage: "",
  },
  reducers: {
    leaveDeleteClearState: (state) => {
      state.leaveDeleteError = false;
      state.leaveDeleteSuccess = false;
      state.leaveDeleteFetching = false;

      return state;
    },
  },
  extraReducers: {
    [deleteLeave.fulfilled]: (state, { payload }) => {
      state.leaveDeleteFetching = false;
      state.leaveDeleteSuccess = true;
      return state;
    },
    [deleteLeave.rejected]: (state, action) => {
      state.leaveDeleteFetching = false;
      state.leaveDeleteError = true;
      state.leaveDeleteErrorMessage = action?.payload;
    },
    [deleteLeave.pending]: (state) => {
      state.leaveDeleteFetching = true;
    },
  },
});

export const { leaveDeleteClearState } = leaveDeleteSlice.actions;

export default leaveDeleteSlice.reducer;
