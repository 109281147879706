import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const editAdmissionDetails = createAsyncThunk(
  "admin/child-admission-edit",
  async ({ payload , childrenID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.childAdmissionDetailURL.replace("{id}",childrenID),
        method: "PATCH",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const editAdmissionDetailsSlice = createSlice({
  name: "child-admission-edit",
  initialState: {
    eadFetching: false,
    eadSuccess: false,
    eadError: false,
    eadErrorMessage: "",
  },
  reducers: {
    clearEadState: (state) => {
      state.eadError = false;
      state.eadSuccess = false;
      state.eadFetching = false;

      return state;
    },
  },
  extraReducers: {
    [editAdmissionDetails.fulfilled]: (state, { payload }) => {
      state.eadFetching = false;
      state.eadSuccess = true;
      return state;
    },
    [editAdmissionDetails.rejected]: (state, action) => {
      state.eadFetching = false;
      state.eadError = true;
      state.eadErrorMessage = action?.payload;
    },
    [editAdmissionDetails.pending]: (state) => {
      state.eadFetching = true;
    },
  },
});

export const { clearEadState } = editAdmissionDetailsSlice.actions;

export default editAdmissionDetailsSlice.reducer;
